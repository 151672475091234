import React from "react";
import styled from "styled-components";
import Gmenu from "./Gmenu";
import PayPalButton from "./Paypal";
import { NavLink } from "react-bootstrap";
import { FaPaypal } from 'react-icons/fa'


const Donate = () => {
  return (
    <>
      {<Gmenu />}
      <Donationbutton className="bg-slate-900  min-h-screen min-w-screen p-5 relative top-20">
        <div className="container bg-slate-800 p-8 d-flex flex-col justify-center align-middle gap-24 translate-y-12"
        style={{}}>
          <h3 className="text-center text-light h1-title">
            {" "}
            Please Choose a Payment Method 🙏{" "}
          </h3>
        </div>

        <div className="payment d-flex justify-evenly align-middle gap-3 relative container top-16">
          <div className="online card bg-slate-800 top-24 ">
            <h3 className="text-center p-2 text-warning">Revolute | Paypal</h3>
             <p>
             <NavLink
                href="https://revolut.me/kkagoha"
                className="paypal-link  text-center"
                style={{
                  padding: "5px 1rem",
                  fontFamily: "sans-serif",
                  fontSize: "1.3rem",
                  color: '#000000',
                  minWidth: '10vw',
                  borderRadius: '5px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
              <button className="d-flex text-danger  px-3 py-2 revolute bg-slate-900 "
              style={{borderRadius:'25px',
                boxShadow:' box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;'
              }}
              >
               
                <span className="text-center text-light text-2xl"> <span  className="me-2"
                 style={{color:'#042aea'}}>Donate via</span>
                   <span style={{color:'#042aea', fontWeight: '800', fontFamily:'verdana'}}>Revolute</span>
                <span style={{color:'#042aea', fontWeight:'bold', fontFamily:'verdana'}}></span></span>
              </button>
              </NavLink>
              
              
              
               </p>
            <div className="button d-flex justify-center align-middle flex-col gap-3 px-6">
            <NavLink
                href="https://paypal.me/igbocommunity"
                className="paypal-link bg-yellow-400 text-center"
                style={{
                  padding: "5px 1rem",
                  fontFamily: "sans-serif",
                  fontSize: "1.3rem",
                  color: '#000000',
                  minWidth: '10vw',
                  borderRadius: '5px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
              <button className="d-flex bg-warning px-3 py-2"
              style={{borderRadius:'25px',
                boxShadow:' box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;'
              }}
              >
                <FaPaypal style={{ marginRight: '8px', color:'blue', fontSize:'2rem'}}/>
                <span className="text-center text-light text-2xl"> <span  className="me-2" style={{color:'#1F2751'}}>Donate via</span>
                   <span style={{color:'#27346A', fontWeight: '800', fontFamily:'verdana'}}>Pay</span>
                <span style={{color:'#2790C3', fontWeight:'bold', fontFamily:'verdana'}}>Pal</span></span>
              </button>
              </NavLink>
              
              
            </div>
          </div>
          <div className="bank card bg-slate-800 text p-2 relative top-24 pt-4">
            <h4 className="text-center  text-bold text-warning">Donate via Bank Account</h4>
            <div className="d-flex flex-col justify-center align-middle bank"
            style={{
              display:'flex', 
              flexDirection: 'column',
              justifyContent:'center',
              alignItems: 'center'

            }}
            
            >
              <div className="d-flex flex-col  px-6 mt-3">
             <p className="text-light font-semibold text-left">NAME: Assoc Igbo Association Donegal</p> 
              <p className="text-light font-semibold text-left">AccNo: 27172/85</p>
              <p className="text-light font-semibold text-left">IBAN: </p>
              </div>
            </div>
          </div>
        </div>
      </Donationbutton>
    </>
  );
};

export default Donate;
const Donationbutton = styled.div`
.revolute{

  span{
    color:white !important;
  }
}
  min-width: 100vw;
  min-height: 100vh;
  z-index: 4000;
  .h1-title {
    max-height: 80px;
  }
  .card {
    padding-top: 1rem;
    min-height: 40vh;
    :nth-child(1) {
      background-color: #1e293b;
      width: 50%;
    }
    :nth-child(2) {
      background-color: #1e293b;
      width: 50%;
    }
  }
  @media (max-width: 786px) {
    .payment {
      display: grid !important;
      grid-template-columns: repeat(1, auto);
      justify-content: center;
      align-items: center;
    }
    .card {
      :nth-child(1) {
        width: 90vw;
      }
      :nth-child(2) {
        width: 90vw;
      }
    }
  }
  .bank{

    p{
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
`;



/* 

const App = () => {
    const [data, setData] = useState(undefined);
 
    const options = [
        "HTML",
        "CSS",
        "JavaScript",
        "React",
        "Redux",
    ];
    const onOptionChangeHandler = (event) => {
        setData(event.target.value);
        console.log(
            "User Selected Value - ",
            event.target.value
        );
    };
    return (
        <center>
            <h1 className="geeks"> GeeksforGeeks</h1>
            <h3>HTML select tag in React js</h3>
 
            <select onChange={onOptionChangeHandler}>
                <option>Please choose one option</option>
                {options.map((option, index) => {
                    return (
                        <option key={index}>
                            {option}
                        </option>
                    );
                })}
            </select>
            <h3>You selected: {data} </h3>
        </center>
    );
};


*/