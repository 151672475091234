import { useState } from "react";
import {
  Navbar,
  NavLink,
  Container,
  Nav,
  Offcanvas,
  CloseButton,
  Button,
} from "react-bootstrap";
import { BsChevronCompactDown } from "react-icons/bs";
import { BsJustify } from "react-icons/bs";
import "../../../CSS/MenuBar.scss";
import logos from "../../image/NewImagelOGO.png";
import styled from "styled-components";
import Register from "./Register";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DisplayMember from "./DisplayMember";

function TopMenu() {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [clicks, setClick] = useState(false);

  let navigate = useNavigate();

  const location = useLocation();
  console.log(location.state);
  const handleOffcanvasClose = () => setShowOffcanvas(false);
  const handleOffcanvasToggle = () =>
    setShowOffcanvas((prevState) => !prevState);

  return (
    <Navbar
      expand="lg"
      variant="light"
      className=" py-2 nav-cover fixed-top"
    >
      <div
        className="navbar-off-canva-container container-fluid px-5"
      >
        <div
          href="#"
          className="brand-logo relative"
        >
          <NavLink
            href="/"
            className="logo  relative cursor-pointer "
          >
            ICNWI
          </NavLink>
          <span className="logo-text px-3">
            <span >organization with clear purpose | </span>
          </span>
          <NavLink
            className=" relative  focus:outline-none shadow-none donor"
            as={Link}
            to="/Payment"
          >
            <span
              className="btn absolute ml-16 donated-page"
            >
              Donate
            </span>
          </NavLink>
        </div>
        <div
          style={{ zIndex: "17000" }}
          className="navbar-toggle relative translate-x-4 shadow-none "
          aria-controls="offcanvasExample"
          onClick={handleOffcanvasToggle}
        >
          <BsJustify
            className="hamburga -translate-x-16
           Bjustify hover-cursor"
          />

          <Button className="btn-menu border-none shadow-none focus:outline-none">
            <span className="btn-menu-text">
              Menu
            </span>
            <span className="btn-icon pionter-cursor">
              <BsChevronCompactDown style={{ fontSize: "2.5rem" }} />
            </span>
          </Button>
        </div>

        <Canva
          className="of-canva"
          show={showOffcanvas}
          onHide={handleOffcanvasClose}
          placement="start"
        >
          <Offcanvas.Header>
            <Offcanvas.Title>
              <NavLink href="/"
                className="navbar-brand text-decoration-none relative nav-side-bar"
              
              >
                <img
                  src={logos}
                  alt={"newLogo1"}
                  style={{
                    position: "absolute",
                    top: "-3rem",
                    left: "-12px",
                    maxWidth: "200px",
                    maxHeight: "200px",
                    minWidth: "100px",
                    zIndex: "17000",
                  }}
                />
              </NavLink>
            </Offcanvas.Title>
            <CloseButton
              variant="white"
              className=""
              style={{ zIndex: "17000" }}
              onClick={() => {
                setShowOffcanvas(handleOffcanvasClose);
              }}
            />
          </Offcanvas.Header>
          <Offcanvas.Body
            className=" relative top-5"
            style={{ zindex: "2500" }}
          >
            <Nav className="" style={{ zindex: "17000" }}>
              <LINKERS
                as={Link}
                to="/"
                target={"_parent"}
                className=" bg-transparent focus:outline-none shadow-none"
              >
                <span className="span">Home</span>
              </LINKERS>

              <LINKERS
                className=" bg-transparent focus:outline-none shadow-none border border-secondary"
                as={Link}
                to="/Gallary"
              >
                <span className="span">Gallery</span>
              </LINKERS>

              <LINKERS
                className=" bg-transparent focus:outline-none shadow-none"
                as={Link}
                to="/Videos"
              >
                <span className="span">Videos</span>
              </LINKERS>

              <LINKERS
                className="bg-transparent focus:outline-none shadow-none"
                as={Link}
                to="/Login"
              >
                <span className="span">PrivatePages</span>
              </LINKERS>

              <LINKERS
                className=" bg-transparent focus:outline-none shadow-none"
                as={Link}
                to="/Login"
              >
                <span className="span">SignIn</span>
              </LINKERS>

              <LINKERS
                className=" bg-transparent focus:outline-none shadow-none"
                as={Link}
                to="/UpdatePage"
              >
                <span className="span">Notification</span>
              </LINKERS>

              <LINKERS
                className=" bg-transparent focus:outline-none shadow-none"
                as={Link}
                to="/Acheivement"
              >
                <span className="span">Acheivements</span>
              </LINKERS>

              <LINKERS
                className=" bg-transparent focus:outline-none shadow-none"
                as={Link}
                to="/BlogPages"
              >
                <span className="span">Historical Origins</span>
              </LINKERS>
              <LINKERS
                className=" bg-transparent focus:outline-none shadow-none"
                as={Link}
                to="/TermsModal"
              >
                <span className="span">TermsModal</span>
              </LINKERS>
            </Nav>

            {/*        OFFCANVAS COMPONENT STARTS HERE
             */}
          </Offcanvas.Body>
        </Canva>

        <Nav className="buttons-right px-5 ">
          <Button
            variant="outline-primary btn1 outline-none shadow-none btn-sign-in "
            className="me-3"
            onClick={() => navigate("/Login")}
          >
            <span>Sign-in</span>
          </Button>

          <span>
            <Register />
          </span>
        </Nav>
      </div>
    </Navbar>
  );
}
export default TopMenu;

const Canva = styled(Offcanvas)`
  z-index: 30000;
  background: #1b001b;
  /*   overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #c3b1e1;
  }

  &::-webkit-scrollbar-thumb {
    background: #51414f;
    border-radius: 25px;
  } */

  .btn-close {
    color: white !important;
    margin-top: 18px !important;
    margin-right: 8px !important;
  }
  .span {
    text-align: center !important;
  }
`;

const LINKERS = styled(Button)`
  z-index: 5000;
  color: #fff3b0 !important;
  border-bottom: 1px solid #fff3b0;
  font-size: 1.2rem !important;
  font-family: "Arial" sans-serif;
  font-weight: 700;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  max-height: 60px !important;
  min-height: 60px !important;
  display: grid;
  grid-template-columns: repeat(1, auto);
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 40px;
  transition: all 0.5s ease-in-out;
  text-align: center;
  &:hover {
    background: #eb5151 !important;
    color: black !important;
    cursor: pointer;
  }
  span {
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    top: 50%;

    @media (max-width: 996px) {
      top: 1.3rem !important;
    }
    @media (max-width: 786px) {
      font-size: 1.5rem !important;
      top: 1rem !important;
    }
  }
  /* Donates page styles */

 
`;

const NAVS = styled(Navbar)`
 .hamburga{
  z-index:3000;
 }
  @media (max-width: 400px) {
    .hamburga {
      transform: translateX(2rem) !important;
    }
  }
  @media (max-width: 992px) {
    .navbar-toggle {
      display: block !important; // Show toggle button on smaller screens
    }

    .buttons-right {
      display: none; // Hide buttons on smaller screens
    }
  }
  @media (max-width: 1200px) {
    .navbar-toggle {
      .btn-menu {
        transform: translateX(1rem) !important;
      }
    }
  }
`;
