import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import hand from "../../image/hand.png";
import "../../../CSS/SignUps.scss";
import Axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import TopMenu from "./TopMenu";
import InputMask from "react-input-mask";
import TermsModal from "../../TermsModal";

const SignUp = () => {
  const navigate = useNavigate();
  const refs = useRef(null);
  const ref_name = useRef(null);
  const ref_nextkin = useRef(null);
  const ref_pw2 = useRef(null);
  const ref_pw = useRef(null);
  const [success, setSuccess] = useState("");
  const [error_name, setErrorName] = useState("");
  const [error_pw, setErrorPw] = useState("");
  const [error_nextkin, setErrorNextkin] = useState("");
  const [error_pw2, setErrorPw2] = useState("");
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [nextkin, setNextKin] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [display, setDisplay] = useState("");

  const handleChanges = (e) => {
    setPhoneNumber(e.target.value);
  };
  
  const successMessage = () => {{
    if(refs.current){
    refs.current.innerHTML = `
        Congrats ${ref_name.current.value}! Welcome onBoard.
        <a href="/Donate" style="color: #007BFF; padding: 3px; text-decoration: none; display: inline-block; margin-top: 10px; border-radius: 10px; text-shadow: 2px 2px 5px rgba(255, 255, 255, 0.5);">
            Proceed to Payment
        </a>
    `;
    if(window.innerWidth <= 400){
      refs.current.style.color = "orange";
      refs.current.style.fontWeight = "300";
      refs.current.style.fontFamily = "sans-serif";
      refs.current.style.textTransform = "capitalize";
      refs.current.style.fontSize = "1rem";
      refs.current.style.marginTop= "3rem";
    }else{
    refs.current.style.color = "orange";
    refs.current.style.fontWeight = "300";
    refs.current.style.fontFamily = "sans-serif";
    refs.current.style.textTransform = "capitalize";
    refs.current.style.fontSize = "1rem";
     refs.current.style.marginTop = "0";
    }
    }
   }
  };



    const handleRegisterClick = async () => {
    const refName = ref_name.current.value;
    const refPassword = ref_pw.current.value;
    const refPassword2 = ref_pw2.current.value;
    const refNextOfkin = ref_nextkin.current.value;

    const hasSpecialCharacters = /[^\w\s;:&%$£"()#~?<>]/.test(refName);
    const hasUppercase = /[A-Z]/.test(refPassword);
    const hasLetters = /[a-z]/.test(refNextOfkin);

    if (!refName) {
      setErrorName("Please Users Input Fields Should not be Empty");
    } else if (
      ref_name.current.value &&
      ref_name.current.value.includes(hasSpecialCharacters)
    ) {
      setErrorName("Special characters are not allowed please");
    } else if (!refNextOfkin) {
      setErrorNextkin("Please Next of Kin Input Fields Should not be Empty");
    } else if (!refPassword) {
      setErrorPw("Please Password Input Fields Should not be Empty");
    } else if (!refPassword2) {
      setErrorPw("Please Password Input Fields Should not be Empty");
    } else if (password.length < 6) {
      setErrorPw("Password must contain at least 6 characters");
    } else if (refPassword !== refPassword2) {
      setErrorPw("Passwords do not match. Please re-enter your passwords");
    } else if (!hasUppercase) {
      setErrorPw("Password must contain at least one uppercase letter");
    } else if (hasLetters) {
      setErrorNextkin("Next of Kin must be Phone Numbers only, Please");
    } else {
      try {
        const response = await Axios.get(
         /* "http://localhost:3000/php/main/display.php"*/
        "https://igbocommunitynwi.com/php/main/display.php"
        )
          .then((response) => {
            const namesArray = response.data.split("\r\n");
            const cleanedNames = namesArray.filter((name) => name.trim() != "");
            const refValue = ref_name.current.value;

            for (var i = 0; i < cleanedNames.length; i++) {
              console.log("LOOP VALUE ", cleanedNames[i]);
              console.log("REF cureent value ", refValue);
              const cleanedName = cleanedNames[i].trim().toLowerCase();
              const inputValue = refValue.trim().toLowerCase();
              if (cleanedName === inputValue) {
                setErrorName(
                  `Username ${inputValue} Already Exist. Please Choose Another Name!`
                );
                console.log("user exists");
                return;
              }
            }
            setShowOffcanvas(true);
          })
          .catch((error) => {
            console.error("Error fetching names:", error);
          });
      } catch (err) {
        console.log(err.stack);
      }
    }
  };

  const handleSubmit = async () => {
    const refName = ref_name.current.value;
    const refPassword = ref_pw.current.value;
    const refPassword2 = ref_pw2.current.value;
    const refNextOfkin = ref_nextkin.current.value;

    if (!checkboxChecked) {
      setError(
        "Please you must read and acknowledge the terms and conditions first."
      );
    } else {
      try {
        // Proceed with registration
           const registerResponse = await Axios.post(
           /*"http://localhost:3000/php/main/reg.php",*/

         "https://igbocommunitynwi.com/php/main/reg.php",
          {
            name: refName,
            nextkin: refNextOfkin,
            password: refPassword,
            password2: refPassword2,
          }
        );

        setErrorName("");
        setErrorNextkin("");
        setErrorPw2("");
        setErrorPw("");
        setError("");
        successMessage();
        setShowOffcanvas(false);
        document.getElementById("forms").reset();
      } catch (err) {
        console.error("Error occurred:", err);
      }
    }
  };

  const handleCancel = () => {
    setShowOffcanvas(false); // Hide the offcanvas when cancel button is clicked
  };
  return (
    <section>
      <TopMenu className="fixed top-0" />
      <SIGNUP className="sign-ups min-w-screen min-h-screen relative register-form">
        <div className="container form-containers relative">
          <h3 className="relative top-1" ref={refs}>
            sign up 
          </h3>
          <p className="text-light absolute top-24">{success}</p>

          <form action="" className="forms" id="forms">
            <div className="relative">
              <label htmlFor="name">Member</label>

              <input
                style={{ zIndex: "1200" }}
                type="text"
                placeholder="Enter you name or Email"
                className="form-control"
                ref={ref_name}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
              <p
                className="  text-light text-center  "
                style={{ maxHeight: "5px", padding: "0" }}
              >
                {error_name}
              </p>
            </div>

            <div>
              <label htmlFor="nextkin">NextOfKin-Phone</label>
              <InputMask
                mask="+999 999 999 999"
                maskChar="_"
                value={nextkin}
                onChange={(e) => setNextKin(e.target.value)}
              >
                {(inputProps) => (
                  <input
                    {...inputProps}
                    type="text"
                    placeholder="Enter phone number only"
                    className="form-control"
                    ref={ref_nextkin}
                  />
                )}
              </InputMask>
              {error_nextkin && (
                <h6
                  className="text-light text-center"
                  style={{ maxHeight: "5px", padding: "0", margin: "0" }}
                >
                  {error_nextkin}
                </h6>
              )}
            </div>

            <div>
              <label htmlFor="text">Password</label>
              <input
                type="password"
                placeholder="Enter your Password"
                className="form-control"
                ref={ref_pw}
                onChange={(e) => setPassword(e.target.value)}
              />
              {error_pw && (
                <h6
                  className=" text-light text-center"
                  style={{ maxHeight: "5px", padding: "0", margin: "0" }}
                >
                  {error_pw}
                </h6>
              )}
            </div>
            <div>
              <label htmlFor="text">Confirm Password</label>
              <input
                type="password"
                placeholder="Confirm your Password"
                className="form-control"
                ref={ref_pw2}
                onChange={(e) => setPassword2(e.target.value)}
              />
              {error_pw2 && (
                <h6
                  className=" text-light text-center"
                  style={{ maxHeight: "5px", padding: "0", margin: "0" }}
                >
                  {error_pw2}
                </h6>
              )}
            </div>
            <div>
              <button
                type="button"
                onClick={handleRegisterClick}
                className="btn btn-lg btn-info form-control"
              >
                Sign up
              </button>
            </div>
            <div className="account-condition mt-2">
              <h6 className="tex-primary">
                <span text-primary>Already a Member ?</span>{" "}
                <Link to="/Login" className="text-decoration-none me-5 ml-3">
                  Sign In
                </Link>
              </h6>
            </div>
          </form>

          {showOffcanvas && (
      
         <div className="term min-w-screen bg-slate-800"
         style={{display:'flex',
          flexDirection:'column',
          alignItems:'center',
          justifyContent:'center',
          marginTop:'-32rem'


         }}>
          <TermsModal
            show={showOffcanvas}
            onClose={handleCancel}
            checkboxChecked={checkboxChecked}
            setCheckboxChecked={setCheckboxChecked}
            handleSubmit={handleSubmit}
            error={error}
          />
         </div> 
          )
          } 
        </div>
      </SIGNUP>
    </section>
  );
};

export default SignUp; 








const SIGNUP = styled.div`

  
`;


/* const ModalContainer = styled.div`
  position: fixed;
  top: 0%;
  left: 0%;
  right:0%;
  bottom:0%;
  width: 100vh;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 145000;
  overflow: scroll;
  background-color:red ;
  animation: slideDown 0.5s ease; 

  @keyframes slideDown {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  .modal-content {
  height:100vh;
  width:100%;
  position:absolute;
  left:0;
  right:0;
  top:0%;

    background-color: #fcfcfc;
    border-radius: 8px;
    background-color: #fff;
    padding: 40px 4rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    animation: slideDown 0.3s ease forwards;
    h5 {
      font-family: Arial, Helvetica, sans-serif;
      color: black;
    }
    labe {
      display: block;
      margin-bottom: 10px;
    }
    input[type="checkbox"] {
      padding: 5%;
      border: 1px solid black !important;
    }
    p {
      font-family: sans-serif;
      font-weight: 300;
      font-style: normal;
    }
    h2 {
      margin-top: 0;
      color: #000 !important;
      font-family: Arial, Helvetica, sans-serif;
    }
  }
`;
 */