import React from 'react';
import styled from 'styled-components';
/* import './ModalCSS/Modal.scss'
 */



   const Text = ({ show, onClose, checkboxChecked, setCheckboxChecked, handleSubmit, error }) => {
 // Conditionally render based on `show`
  
    return (
      <div className="modal-containar w-screen h-screen bg-zinc-900" >
  
        <div className="modal-content container relative">
          <h2 className="pb-3 pt-10 mt-10">ICANWI Code of Conduct</h2>
          <h4 className="text-primary">
            Please read and acknowledge the terms and conditions
          </h4>
          <p>
          <p>
                    Our Code of Conduct serves as a foundational framework of
                    principles and guidelines meticulously crafted to govern the
                    behavior of all members within the ICANWI community. It
                    reflects our collective commitment to fostering an environment
                    of respect, integrity, and inclusivity, ensuring that every
                    interaction, whether in meetings or events, upholds the values
                    we hold dear.
                  </p>
                  <h5 className="relative top-1 text-primary">
                 Key Principles:
                  </h5>
                  <p>
                    <span className="font-bold me-1">
                      Respect:
                    </span>
                    Treat every member with dignity and respect, regardless of
                    differences in opinion, background, or status. Valuing diverse
                    perspectives enriches our community.
                    <br />
                    <span className="font-bold me-1">Integrity:</span> Conduct
                    yourself with honesty and transparency in all interactions.
                    Uphold the highest ethical standards, both within the group
                    and in external engagements.
                    <br />
                    <span className="font-bold me-1">Collaboration:</span> Foster
                    a spirit of collaboration and cooperation, recognizing that
                    collective effort and teamwork are essential for achieving our
                    shared goals.
                    <br />
                    <span className="font-bold me-1">Accountability:</span> Take
                    responsibility for your actions and their impact on the
                    community. Admit mistakes, learn from them, and strive to do
                    better.
                    <br />
                    <h5 className="relative top-2 bottom-2 text-primary">
                      <strong>Rules and Guidelines:</strong>
                    </h5>
                    <span className="font-bold me-1">Professionalism:</span>{" "}
                    Foster a spirit of collaboration and cooperation, recognizing
                    that collective effort and teamwork are essential for
                    achieving our shared goals.
                    <br />
                    <span className="font-bold me-1">Confidentiality:</span>{" "}
                    Respect the privacy and confidentiality of fellow members.
                    Refrain from disclosing sensitive information shared within
                    the group without explicit consent.
                    <br />
                    <span className="font-bold me-1">
                      Conflict Resolution:
                    </span>{" "}
                    Resolve conflicts and disagreements amicably and respectfully,
                    seeking mediation or assistance from group leaders when
                    necessary..
                    <br />
                    <span className="font-bold me-1">Compliance:</span>: Adhere to
                    all relevant laws, regulations, and policies governing our
                    activities, both within the group and in external engagements
                    <h5 className="relative top-2 bottom-2 text-primary">
                  Enforcement and Consequences:
                    </h5>
                    <p>
                      {" "}
                      Violation of the Code of Conduct may result in disciplinary
                      action, determined on a case-by-case basis by group leaders
                      or designated moderators. Consequences may include warnings,
                      temporary suspension of privileges, or permanent expulsion
                      from the group, depending on the severity of the offense.
                    </p>
                  </p>
          </p>
          <label className="text-black">
            <input
              type="checkbox"
              checked={checkboxChecked}
              onChange={() => setCheckboxChecked(!checkboxChecked)}
            />
            {error && <p>{error}</p>}
            <span className="text-black ml-3 py-3">
              I have read and agree to the terms and conditions.
            </span>
          </label>
          <button className="btn btn-info btn-lg text-light" onClick={handleSubmit}>
            Submit
          </button>
          <button className="cancel-button btn btn-lg btn-info mt-2 text-light" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    );
  };
  
  export default Text;
  
  
  
  
  