import React from 'react'
import Menu from '../../../Header/DropdownContainer/TopMenu'
import styled from 'styled-components'

import stpatrick from '../../../image/stpatrick.jpg'
import old from '../../../image/oldstpatick.jpeg'
import old1 from '../../../image/oldstpatick1.jpeg'
import video1 from '../../../video/dublin.mp4'
import { useRef } from 'react'
import { Helmet } from 'react-helmet-async'


const Integration = () => {
  const videoRef= useRef();
  const setPlayBack = () => {
    videoRef.current.playbackRate = 0.2;
  };


  return (
    <>
      <Helmet>
   <title>Cultural Integration</title>
   <meta name = "description" content="The Igbo Cultural Association is committed to cultural integration, as evidenced by their showcasing of their cultural values during the St. Patrick's Festival "/>
   <link rel="canonical" href="/Acheivement" />
    </Helmet>
    {<Menu/>}
       <EQUALITY className='p-5 m-auto relative top-0 container'>

       <header>
       <video className="video-background" autoPlay loop muted  ref={videoRef}  onCanPlay={() => setPlayBack()}>
          <source src={video1} type="video/mp4" />
          <source src="path/to/video.webm" type="video/webm" />
          Your browser does not support the video tag.
        </video>
        <div className="titles"><h1> people and their Culture</h1></div>
      </header>
    
      <main>
        <div className="text-page">
          <h2>Cultural Integration</h2>
          <p>
          
          Cultural integration refers to the process of bringing together people from diverse cultural backgrounds and promoting understanding and mutual respect. It involves recognizing and appreciating the differences and similarities between cultures and working towards creating a cohesive society. The Igbo Cultural Association has demonstrated a strong commitment to cultural integration, as evident from their efforts to showcase their cultural values during the St. Patrick's Festival and organize an annual end-of-the-year family get-together program to bring together people from different backgrounds. By promoting cultural understanding and appreciation, they contribute to building a more cohesive society.
          </p>
        </div>
        <div className="grid-container">
          <div className="grid-item item1" >
          <img src={stpatrick} alt="image.title" />
          </div>

          <div className="grid-item item2" >
          <img src={old} alt="image.title" />
          </div>
          <div className="grid-item item2" >
          <img src={old1} alt="image.title" />
          </div>

      
        </div>
      </main>
      </EQUALITY>

    </>
  )
}

export default Integration

const EQUALITY = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  header {
  position: relative;
  display: flex;
  height: 50vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  overflow:hidden;

}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}



h1 {
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
  color:#fdfbfb !important;
}
main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.text-page {
  max-width: 800px;
  margin-bottom: 2rem;
  text-align: left;

}

.text-page h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color:#000;
}

.text-page p {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #555;
  font-size:18px;
  line-height:5vh;
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 3rem;


}

.grid-item {
  position: relative;
  min-width: 400px;
  min-height: 300px;
  background-size: cover;
  background-position: center;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  img{
    min-width:100%;
    max-width:100%;
    min-height:100%;
    max-height:100%;
  }
}

.grid-item:hover {
  transform: scale(1.1);
}

.grid-item-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
}

@media screen and (max-width: 768px) {


  h1 {
    display:none!important;
  }

  .text-page h2 {
    font-size: 2rem !important;
  }

  .text-page {
    min-width:100vw;
    text-align: center !important;
    padding:0 2rem !important;

  p {
    font-size: 19px;
  
  }
  }
  .grid-container {
    max-width: 90vw;
  }

  .grid-item {
    width: calc(33.33% - 0.5rem);
    height: 150px;
  }
}

`